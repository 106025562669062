




































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Role from '@/store/entities/role';

@Component
export default class CreateRole extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    role: Role = new Role();

    get permissions() {
        return this.$store.state.role.permissions
    }

    save() {
        (this.$refs.roleForm as any).validate(async (valid: boolean) => {
            if (valid) {
                if (!this.role.grantedPermissions) {
                    this.role.grantedPermissions = [];
                }
                await this.$store.dispatch({
                    type: 'role/create',
                    data: this.role
                });
                (this.$refs.roleForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.roleForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        }
    }

    roleRule = {
        name: [{
            required: true,
            message: this.L('FieldIsRequired', undefined, this.L('RoleName')),
            trigger: 'blur'
        }],
        displayName: [{
            required: true,
            message: this.L('FieldIsRequired', undefined, this.L('DisplayName')),
            trigger: 'blur'
        }]
    }
}
